import { Builder } from '@builder.io/react'

import Faq from './FAQSection'

const register = () =>
  Builder.registerComponent(Faq, {
    name: 'FAQ Section',
    inputs: [
      {
        name: 'title',
        type: 'string',
        defaultValue: 'perguntas que você pode ter:',
      },
      {
        name: 'questions',
        type: 'list',
        defaultValue: [
          {
            question: {
              answer: 'Exemplo de resposta',
              questionDescription: 'Exemplo de pergunta',
            },
          },
        ],
        subFields: [
          {
            name: 'question',
            type: 'object',
            subFields: [
              {
                name: 'questionDescription',
                type: 'longText',
                required: true,
              },
              {
                name: 'answer',
                type: 'longText',
                required: true,
              },
              {
                name: 'helperLink',
                type: 'object',
                helperText: 'O link será colocado ao fim da resposta',
                subFields: [
                  { name: 'text', type: 'string', required: true },
                  { name: 'href', type: 'link', required: true },
                ],
              },
            ],
          },
        ],
      },
    ],
  })

export default {
  register,
}

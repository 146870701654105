import { Builder } from '@builder.io/react'

import Tag from '../Tag'

import EverythingDigital from './EverythingDigitalSection'

const register = () =>
  Builder.registerComponent(EverythingDigital, {
    name: 'Everything Digital Section',
    inputs: [
      Tag.getComponentDefinition('vagas limitadas', 0),
      {
        name: 'title',
        type: 'longText',
        defaultValue: 'tudo digital, para ficar ainda mais fácil',
      },
      {
        name: 'subtitle',
        type: 'longText',
        defaultValue:
          'Você vai ter acesso a sua conta digital pelo celular ou computador todos os meses. Se precisar de ajuda, nosso time vai estar pronto para te atender!\n\nCorra e garanta sua reserva!',
      },
      {
        name: 'ctaButtonLabel',
        type: 'string',
        defaultValue: 'Simular economia',
      },
      {
        name: 'img',
        type: 'file',
        allowedFileTypes: ['png', 'jpg', 'jpeg'],
      },
    ],
  })

export default {
  register,
}

import { getStates } from '@brazilian-utils/brazilian-utils'
import { useFormContext } from 'react-hook-form'

import BaseSelectInput from './BaseSelectInput'

import errorMessages from '~/constants/errorMessages'
import formValidations from '~/utils/formValidations.util'

const brazilianStates = getStates()

const AddressStateInput = ({
  label = 'Estado',
  testid,
}: {
  label?: string
  testid?: string
}) => {
  const form = useFormContext<{ state?: string }>()

  const {
    register,
    formState: { errors },
  } = form

  return (
    <BaseSelectInput
      label={label}
      error={errors.state?.message}
      {...register('state', {
        required: errorMessages.REQUIRED,
        validate: (value?: string) => {
          if (!value) return errorMessages.REQUIRED
          if (!formValidations.isBrazilianState(value))
            return errorMessages.INVALID_STATE
          return true
        },
      })}
      data-testid={testid}
      data-allow-value-capture
    >
      <option label="" value="" disabled aria-label="Selecione" />

      {brazilianStates.map(item => (
        <option value={item.code} key={item.code}>
          {item.code}
        </option>
      ))}
    </BaseSelectInput>
  )
}

export default AddressStateInput

import { Builder } from '@builder.io/react'

import HowItWorks from './HowItWorksSection'

const register = () =>
  Builder.registerComponent(HowItWorks, {
    name: 'How It Works Section',
    inputs: [
      {
        name: 'title',
        type: 'longText',
        defaultValue: 'Entenda como funciona',
        friendlyName: 'Titulo',
      },
      {
        name: 'subtitle',
        type: 'longText',
        defaultValue: 'Não tem burocracia ou papelada, é 100% digital.',
        friendlyName: 'Subtitulo',
      },
      {
        name: 'steps',
        type: 'list',
        subFields: [
          {
            name: 'title',
            friendlyName: 'Titulo da etapa',
            type: 'string',
          },
          {
            name: 'description',
            friendlyName: 'Descrição da etapa',
            type: 'string',
            required: true,
            helperText:
              'Deixe esse campo vazio para deixar a etapa em destaque',
          },
          {
            name: 'img',
            required: true,
            friendlyName: 'Imagem da etapa',
            type: 'file',
            allowedFileTypes: ['png', 'jpeg', 'jpg'],
          },
        ],
      },
      {
        name: 'withActionButton',
        friendlyName: 'Botão de redirecionamento',
        type: 'boolean',
        defaultValue: false,
      },
    ],
  })

export default {
  register,
}

import AddressStateInput from '~/components/form/AddressStateInput'
import BaseTextInput from '~/components/form/BaseTextInput'
import BusinessNameInput from '~/components/form/BusinessNameInput'
import EmailInput from '~/components/form/EmailInput'
import NameInput from '~/components/form/NameInput'
import PhoneNumberInput from '~/components/form/PhoneNumberInput'
import PolicyConsentInput from '~/components/form/PolicyConsentInput'
import errorMessages from '~/constants/errorMessages'
import StateDisco from '~/routes/__site/$landingPath/__components/customFormFields/StateDisco'

type FormFieldProps = {
  label?: string
  name:
    | 'fullName'
    | 'email'
    | 'phoneNumber'
    | 'businessName'
    | 'state'
    | 'stateDisco'
    | 'externalPartnerIdOnlyCNPJ'
    | 'ambevPOSCode'
    | 'termsOfUse'
    | 'externalPartnerId'
}

const FormField = (props: FormFieldProps) => {
  const inputSelection = {
    fullName: <NameInput {...props} />,
    email: <EmailInput {...props} />,
    phoneNumber: <PhoneNumberInput {...props} />,
    businessName: <BusinessNameInput {...props} />,
    state: <AddressStateInput {...props} />,
    stateDisco: <StateDisco />,
    externalPartnerIdOnlyCNPJ: (
      <BaseTextInput
        name="externalPartnerIdOnlyCNPJ"
        type="text"
        inputMode="decimal"
        label="CNPJ do estabelecimento"
        mask="cnpj"
        errors={{
          REQUIRED: errorMessages.REQUIRED,
          INVALID: errorMessages.INVALID_CNPJ,
        }}
      />
    ),
    ambevPOSCode: (
      <BaseTextInput
        name="ambevPOSCode"
        type="text"
        label="Código do PDV da Ambev"
      />
    ),
    termsOfUse: <PolicyConsentInput />,
    externalPartnerId: (
      <BaseTextInput
        name="externalPartnerId"
        type="text"
        inputMode="decimal"
        label="CPF ou CNPJ do estabelecimento"
        mask="cpfOrCnpj"
        errors={{
          REQUIRED: errorMessages.REQUIRED,
          INVALID: errorMessages.INVALID_CPF_OR_CNPJ,
        }}
      />
    ),
  }

  return (
    inputSelection[props.name] || <p>Selecione um valor para o campo "name"</p>
  )
}

export default FormField

import { Builder } from '@builder.io/react'

import LemonPartners from './CPFLLemonPartners'

const register = () =>
  Builder.registerComponent(LemonPartners, {
    name: 'CPFL Lemon Partners',
    inputs: [
      {
        name: 'title',
        type: 'longText',
        defaultValue: 'Conheça quem já conta com a Lemon',
      },
      {
        name: 'subtitle',
        type: 'longText',
        defaultValue: 'Mais de 5 mil clientes economizando todos os meses.',
      },
      {
        name: 'partners',
        type: 'list',
        defaultValue: [],
        subFields: [
          {
            name: 'logo',
            type: 'file',
            allowedFileTypes: ['png', 'jpeg', 'jpg'],
            required: true,
          },
          {
            name: 'name',
            type: 'string',
            friendlyName: 'Nome do parceiro',
            required: true,
          },
        ],
      },
    ],
  })

export default {
  register,
}

import { Button, Grid } from '@lemonenergy/lemonpie'
import type { BaseSyntheticEvent } from 'react'
import styled, { css } from 'styled-components'

import Mockup from 'public/mockup3_como-funciona.png'
import Image from '~/components/Image'
import Subtitle from '~/components/Subtitle'
import Tag from '~/components/Tag'
import Title from '~/components/Title'
import { gtag } from '~/utils/gtagEvents'

const SavingsSection = styled.section(
  ({ theme: { spacing, media } }) => css`
    padding: ${spacing(9)} 0 0;

    ${TitleCol} {
      margin-bottom: ${spacing(8)};
    }

    ${media.md} {
      padding: ${spacing(30)} 0 0;

      ${TitleCol} {
        margin-bottom: ${spacing(30)};
      }
    }
  `,
)

const TitleCol = styled(Grid.Col)(
  ({ theme: { spacing, media } }) => css`
    > ${Title} {
      margin-bottom: ${spacing.md};
    }

    > ${Subtitle} {
      margin-bottom: ${spacing.md};
    }

    > ${Button} {
      max-width: 312px;
    }

    ${media.between('xs', 'md')} {
      > ${Button} {
        margin: 0 auto;
      }
    }
  `,
)

const MockupCol = styled(Grid.Col)(
  ({ theme: { spacing } }) => css`
    position: relative;

    ${Tag} {
      position: relative;
      z-index: 1;
      justify-self: center;
      align-self: center;
      right: ${spacing(9)};
      top: ${spacing.md};
    }
  `,
)

const MockupImage = styled(Image)(
  () => css`
    &&& {
      background-size: contain;
      background-position: bottom center;
    }
  `,
)

type GenSavingsProps = {
  link: string
}

const Savings = ({ link }: GenSavingsProps) => {
  const trackButtonClick = (e: BaseSyntheticEvent) =>
    gtag(
      {
        event: 'click_interaction',
        source_id: 'savings-button',
        interaction_type: 'button',
        label:
          'Botão da seção de economia que direciona o usuário para o formulário',
      },
      e,
    )

  return (
    <SavingsSection>
      <Grid.Container>
        <Grid.Row>
          <TitleCol md={6} order={{ xs: 1, md: 2 }}>
            <Title>economia</Title>
            <Subtitle>
              O consumo da sua energia limpa é mais econômica do que a energia
              comum da distribuidora. Você gasta menos com energia, sem precisar
              de obras ou investimentos.
            </Subtitle>
            <Button onClick={trackButtonClick} to={link ?? '#cadastro'}>
              Realizar Cadastro
            </Button>
          </TitleCol>
          <MockupCol
            md={6}
            order={{ xs: 2, md: 1 }}
            justify={{ xs: 'flex-start', md: 'flex-end' }}
          >
            <MockupImage
              src={Mockup}
              height={{ xs: 42, md: '100%' }}
              title="Celular mostrando a conta de energia Lemon. "
              alt="Celular mostrando como a conta de energia Lemon é simples, digital e fácil de entender. "
            />
          </MockupCol>
        </Grid.Row>
      </Grid.Container>
    </SavingsSection>
  )
}

export default Savings

import { useNavigation } from '@remix-run/react'
import { useFormContext } from 'react-hook-form'

import BaseTextInput from './BaseTextInput'

import errorMessages from '~/constants/errorMessages'

const BusinessNameInput = ({
  label,
  testid,
}: {
  label?: string
  testid?: string
}) => {
  const form = useFormContext<{ businessName?: string }>()
  const navigation = useNavigation()

  const {
    register,
    formState: { errors },
  } = form

  const loading = navigation.state !== 'idle'

  return (
    <BaseTextInput
      label={label ?? 'Nome fantasia da empresa'}
      error={errors.businessName?.message}
      disabled={loading}
      data-testid={testid}
      {...register('businessName', {
        required: errorMessages.REQUIRED,
        validate: (value?: string) => {
          const trimedValue = value?.trim()
          if (!trimedValue) return errorMessages.REQUIRED
          if (trimedValue.length < 2)
            return 'O nome deve ter ao menos 2 caracteres.'
          return true
        },
      })}
    />
  )
}

export default BusinessNameInput

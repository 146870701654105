import eligibleStates from '~/constants/eligibleStates'
import type { EligibleState } from '~/constants/eligibleStates'
import geo from '~/constants/geo'

const StatesSelectOptions: React.FC = () => {
  return (
    <>
      <option disabled value="" label=" " />
      <optgroup label="Mais selecionados">
        {geo.brazilianStates
          .filter(state => eligibleStates.includes(state.code as EligibleState))
          .map(({ code, name }) => (
            <option value={code} key={`${code}-${name}`}>
              {name}
            </option>
          ))}
      </optgroup>
      <optgroup label="Ordem alfabética">
        {geo.brazilianStates.map(({ code, name }) => (
          <option value={code} key={`${name}-${code}`}>
            {name}
          </option>
        ))}
      </optgroup>
    </>
  )
}

export default StatesSelectOptions

import { Grid, Text } from '@lemonenergy/lemonpie'
import styled, { css } from 'styled-components'

import Card from '~/components/Card'
import LinkWithIcon from '~/components/LinkWithIcon'
import Section from '~/components/Section'
import { type PublishersNames } from '~/constants/articles'
import { shortRandomName } from '~/utils/hashUtils'

const Container = styled(Grid.Container)(
  ({ theme: { colors } }) => css`
    background-color: ${colors.neutral.light};

    > *:first-child {
      align-self: center;
    }
  `,
)
const Title = styled(Text)(
  ({ theme: { spacing, media, fontSize, lineHeight } }) => css`
    margin-bottom: ${spacing(6)};
    ${media.md} {
      font-size: ${fontSize(6)};
      line-height: ${lineHeight(7.25)};
      align-self: flex-start;
    }
  `,
)

const StyledCard = styled(Card)(
  ({ theme: { spacing, media } }) => css`
    margin-top: ${spacing.md};
    > img {
      margin-bottom: ${spacing.md};
      max-width: 135px;
      max-height: 35px;
    }

    > .valor-img {
      margin-bottom: 21px;
      max-width: 105px;
      max-height: 35px;
    }

    > .megawhat-img {
      margin-bottom: 28.5px;
      min-width: 192px;
      max-height: 31.5px;
    }

    > .canalsolar-img {
      margin-bottom: 23px;
      max-width: 46px;
      max-height: 46px;
    }

    > ${Text} {
      margin-bottom: ${spacing.md};
      height: 100%;
    }

    ${media.only.xs} {
      margin-bottom: ${spacing.md};

      > .valor-img {
        margin-bottom: ${spacing.lg};
      }
    }
  `,
)

type Article = {
  article: {
    link: string
    title: string
    publisherName: PublishersNames
    publisherLogo: string
  }
}
type ArticleSectionType = {
  title: string
  articles: Array<Article>
}
const ArticlesSection = ({ articles = [], title }: ArticleSectionType) => {
  const itemsPerRow = 3
  const articlesByRow = []
  for (let i = 0; i < articles.length; i += itemsPerRow) {
    const chunk = articles.slice(i, i + itemsPerRow)

    articlesByRow.push(chunk)
  }
  return (
    <Section variant="neutral.light">
      <Container>
        <Title bold variant="title100">
          {title}
        </Title>
        {articlesByRow?.map(articles => (
          <Grid.Row key={`row-${shortRandomName()}`}>
            {articles?.map(
              ({ article: { link, publisherLogo, publisherName, title } }) => (
                <Grid.Col xs={12} sm={4} key={`card-${shortRandomName()}`}>
                  <StyledCard bordered>
                    <img
                      alt={`logo da ${publisherName}`}
                      src={publisherLogo}
                      className={`${publisherName}-img`}
                    />
                    <Text bold variant={{ xs: 'subtitle200', sm: 'title300' }}>
                      {title}
                    </Text>
                    <LinkWithIcon
                      name="arrow-right"
                      href={link}
                      target="_blank"
                      rotateIcon={-45}
                    >
                      Ler matéria completa
                    </LinkWithIcon>
                  </StyledCard>
                </Grid.Col>
              ),
            )}
          </Grid.Row>
        ))}
      </Container>
    </Section>
  )
}

export default ArticlesSection

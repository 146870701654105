import { Builder } from '@builder.io/react'

import FormField from './FormField'

const register = () =>
  Builder.registerComponent(FormField, {
    name: 'Form Field',
    inputs: [
      {
        name: 'name',
        type: 'string',
        enum: [
          'fullName',
          'email',
          'phoneNumber',
          'businessName',
          'state',
          'stateDisco',
          'externalPartnerIdOnlyCNPJ',
          'ambevPOSCode',
          'termsOfUse',
          'externalPartnerId',
        ],
      },
      {
        name: 'label',
        type: 'text',
      },
    ],
  })

export default { register }

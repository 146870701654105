import builder, { Builder, BuilderComponent } from '@builder.io/react'
import { Text } from '@lemonenergy/lemonpie'
import {
  type LoaderFunctionArgs,
  type MetaFunction,
  redirect,
} from '@remix-run/node'
import { useLoaderData, useSubmit } from '@remix-run/react'
import { useEffect } from 'react'
import type { SitemapFunction } from 'remix-sitemap'

import { LandingPageFallbackContainer } from './__components/styles'

import registerBuilderComponents from '~/builder'
import constants from '~/constants/index.server'
import {
  getBuilderPage,
  redirectFromBees,
} from '~/controllers/app.controller.server'
import { experimentRedirectFbPh } from '~/controllers/experiments.controller.server'
import session from '~/sessions'
import cacheUtils from '~/utils/cache.utils.server'
import commonMetaTags from '~/utils/commonMetaTags.util'
import { requireParams } from '~/utils/request.utils.server'
import { parseUtms } from '~/utils/utms.utils'

builder.init(constants.builderPublicKey)

registerBuilderComponents()

export const handle = {
  isHeaderHidden: true,
}

const RegexAllowedIndaxablePaths = /\/bees-fev24/g

export const sitemap: SitemapFunction = async () => {
  const allPages = await builder.getAll('page', {
    options: { noTargeting: true },
    fields: 'lastUpdated,data.url',
    query: {
      data: {
        url: { $regex: RegexAllowedIndaxablePaths },
      },
    },
  })

  return allPages.map(page => ({
    loc: (page?.data?.url ?? '').slice(1),
    lastmod: new Date(page?.lastUpdated ?? new Date()).toString(),
  }))
}

export const loader = async ({
  request,
  params,
  context: { userIdentityId, contextSetPosthogInstance },
}: LoaderFunctionArgs) => {
  const url = new URL(request.url)
  const { landingPath } = requireParams(params, ['landingPath'])

  const currentSession = await session.get(request)

  const { utm_source, utm_medium, utm_campaign } = parseUtms(url.searchParams)

  await redirectFromBees({
    paramsPath: landingPath,
    utm_source,
    utm_medium,
    utm_campaign,
    currentSession,
  })

  await experimentRedirectFbPh({
    landingPath: params.landingPath ?? '',
    userIdentityId,
    contextSetPosthogInstance,
  })

  const page = landingPath ? await getBuilderPage(`/${landingPath}`) : null

  const body = {
    baseUrl: constants.baseUrl,
    page,
  }

  const cacheValidation = cacheUtils.validateEtag<typeof body>(
    request,
    JSON.stringify({
      pageLastUpdate: page?.lastUpdated,
      userIdentityId,
    }),
  )
  if (cacheValidation.cacheHit) return cacheValidation.response

  return session.json(currentSession, body, {
    headers: cacheUtils.createCacheHeaders({
      etag: cacheValidation.etag,
      browserStrategy: 'FOR_ONE_MINUTE',
      isPrivate: true,
    }),
  })
}

export const meta: MetaFunction<typeof loader> = ({ data: remixData }) => {
  const titleOverride = remixData?.page?.data?.title
  const descriptionOverride = remixData?.page?.data?.description
  const isIndexable = RegexAllowedIndaxablePaths.test(
    remixData?.page?.data?.url,
  )

  return [
    ...commonMetaTags({
      baseUrl: remixData?.baseUrl,
      title: titleOverride,
      description: descriptionOverride,
    }),
    ...(!isIndexable
      ? [
          {
            name: 'robots',
            content: 'noindex',
          },
        ]
      : []),
  ]
}

export const action = async () => {
  const isProd = constants.env.STAGE === 'prod'
  return redirect(
    isProd
      ? 'https://lmn.page/redirect_old_pages'
      : 'https://beta.lemon.energy/',
  )
}

const LandingPage = () => {
  const { page } = useLoaderData<typeof loader>()
  const submit = useSubmit()

  const isBuilderEditorAccess = Builder.isPreviewing || Builder.isEditing

  useEffect(() => {
    if (!page && !isBuilderEditorAccess) submit(null, { method: 'POST' })
  }, [page, isBuilderEditorAccess, submit])

  if (!page && isBuilderEditorAccess) {
    return <BuilderComponent model="page" />
  } else if (!page) {
    return (
      <LandingPageFallbackContainer>
        <Text>Redirecionando...</Text>
      </LandingPageFallbackContainer>
    )
  }

  return <BuilderComponent model="page" content={page} />
}

export default LandingPage

import { Grid } from '@lemonenergy/lemonpie'
import type { ITheme, ScreenSize } from '@lemonenergy/lemonpie-themes'
import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import LemonPlusAmbevImage from 'public/landing-page_lemon-mais-ambev.png'
import Section from '~/components/Section'
import Title from '~/components/Title'

const TitleCol = styled(Grid.Col)(
  ({ theme: { spacing, media } }) => css`
    align-items: center;
    text-align: center;
    gap: ${spacing(6)};
    margin-bottom: ${spacing(6)};
    > img {
      max-width: 270px;
    }

    ${media.sm} {
      > img {
        max-width: 454px;
      }
    }
  `,
)

const getVideoDimensionStyles = (theme: ITheme): FlattenSimpleInterpolation => {
  return Object.entries(theme.awesomegrid.breakpoints)
    .filter(
      ([breakpoint]) =>
        breakpoint !== 'xl' && breakpoint !== 'xs' && breakpoint !== 'sm',
    )
    .map(([breakpoint, size]) => {
      return css`
        ${theme.media[breakpoint as ScreenSize]} {
          > iframe {
            min-height: calc(${size}rem * 0.56);
          }
        }
      `
    })
}
const VideoCol = styled(Grid.Col)(({ theme }) => {
  const { media, spacing } = theme

  return css`
    align-items: center;
    text-align: center;
    height: calc(100vw * 0.5625);
    padding-bottom: ${spacing(9)};

    > iframe {
      position: absolute;
      left: 0;
      width: 100vw;
      min-height: calc(100vw * 0.5625); // 16:9
      max-width: 1366px;
      max-height: 765px;
    }

    ${media.md} {
      height: auto;
      padding-bottom: 0;
      margin-bottom: 0;
      > iframe {
        width: 100%;
        position: static;
      }
    }

    ${getVideoDimensionStyles(theme)}
  `
})
const AmbevVideo = () => {
  return (
    <Section variant="neutral.darkest">
      <Grid.Container>
        <Grid.Row>
          <TitleCol>
            <img src={LemonPlusAmbevImage} alt="Lemon + Ambev" />
            <Title>cerveja gelada com energia sustentável</Title>
          </TitleCol>
        </Grid.Row>
      </Grid.Container>

      <Grid.Container fluid={{ xs: true }}>
        <Grid.Row>
          <VideoCol>
            <iframe
              src="https://www.youtube-nocookie.com/embed/Tw0RvJ-fznQ"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              style={{
                border: 0,
              }}
              allowFullScreen
            ></iframe>
          </VideoCol>
        </Grid.Row>
      </Grid.Container>
    </Section>
  )
}
export default AmbevVideo

import { Grid, Text } from '@lemonenergy/lemonpie'
import type { ResponsiveProp } from '@lemonenergy/lemonpie/dist/types/utils'
import type { ITheme, ScreenSize } from '@lemonenergy/lemonpie-themes'
import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import Title from '~/components/Title'

const getBackgroundSizeStyles = (
  theme: ITheme,
  backgroundSize?: HeroProps['backgroundSize'],
): FlattenSimpleInterpolation => {
  if (typeof backgroundSize === 'object')
    return Object.entries(backgroundSize).map(
      ([breakpoint, responsiveBackgroundSize]) => css`
        ${theme.media[breakpoint as ScreenSize]} {
          ${getBackgroundSizeStyles(theme, responsiveBackgroundSize)}
        }
      `,
    )

  return css`
    background-size: ${backgroundSize ?? 'cover'};
  `
}

const getBackgroundPositionStyles = (
  theme: ITheme,
  backgroundPosition?: HeroProps['backgroundPosition'],
): FlattenSimpleInterpolation => {
  if (typeof backgroundPosition === 'object')
    return Object.entries(backgroundPosition).map(
      ([breakpoint, responsiveBackgroundPosition]) => css`
        ${theme.media[breakpoint as ScreenSize]} {
          ${getBackgroundPositionStyles(theme, responsiveBackgroundPosition)}
        }
      `,
    )

  return css`
    background-position: ${backgroundPosition ?? 'top center'};
  `
}

interface HeroProps {
  title: string
  description: string
  image: string
  imageMobile?: string
  backgroundColor?: string
  color?: string
  backgroundPosition?: ResponsiveProp<string>
  backgroundSize?: ResponsiveProp<string>
}

const Container = styled.section<Omit<HeroProps, 'title' | 'description'>>(
  ({
    image,
    imageMobile,
    backgroundColor,
    color,
    backgroundPosition,
    backgroundSize,
    theme,
  }) => {
    const { colors, media, spacing } = theme

    return css`
      padding-bottom: ${spacing.md};
      background-image: url(${image});
      background-color: ${backgroundColor ?? colors.primary.main};
      background-repeat: no-repeat;

      min-height: 532px;
      color: ${color ?? colors.neutral.lightest};

      &,
      > ${Grid.Container} {
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      > ${Grid.Container} {
        flex: 1 0 100%;
        min-height: 100%;
      }

      ${media.sm} {
        min-height: 587px;
      }

      ${media.only.xs} {
        background-image: url(${imageMobile ?? image});
      }

      ${getBackgroundSizeStyles(theme, backgroundSize)}
      ${getBackgroundPositionStyles(theme, backgroundPosition)}
    `
  },
)

const TextCol = styled(Grid.Col)(
  ({ theme: { spacing } }) => css`
    gap: ${spacing.md};
  `,
)

const GenHero: React.FC<HeroProps> = props => {
  const { title, description, ...containerProps } = props

  return (
    <Container {...containerProps}>
      <Grid.Container>
        <Grid.Row align={{ xs: 'flex-end', sm: 'center' }}>
          <TextCol sm={6} md={5}>
            <Title>{title}</Title>
            <Text>{description}</Text>
          </TextCol>
        </Grid.Row>
      </Grid.Container>
    </Container>
  )
}

export default GenHero

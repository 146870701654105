import type { iconNames } from '@lemonenergy/lemonpie'
import { Icon, Text } from '@lemonenergy/lemonpie'
import type { BoxSize } from '@lemonenergy/lemonpie/dist/Box/Box'
import type {
  TextVariantOptions,
  TextVariantProp,
} from '@lemonenergy/lemonpie/dist/Text'
import type { ResponsiveProp } from '@lemonenergy/lemonpie/dist/types/utils'
import type { ITheme, ScreenSize } from '@lemonenergy/lemonpie-themes'
import React from 'react'
import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

export type TagBuilderType = {
  icon: (typeof iconNames)[number]
  size: ResponsiveProp<'sm' | 'md' | 'lg'>
  rotation: number
  label: string
}

const getTextStyles = (
  theme: ITheme,
  size: ResponsiveProp<TagSize>,
): FlattenSimpleInterpolation => {
  const textStylesOptions: {
    [key in TagSize]: { fontSize: string; lineHeight: string }
  } = {
    sm: { fontSize: theme.fontSize.sm, lineHeight: theme.lineHeight(2.8) },
    md: { fontSize: theme.fontSize.lg, lineHeight: theme.lineHeight(3.5) },
    lg: { fontSize: theme.fontSize(3.5), lineHeight: theme.lineHeight(4.9) },
  }

  if (typeof size === 'object')
    return Object.entries(size).map(([breakpoint, responsiveSize]) => {
      return css`
        ${theme.media[breakpoint as ScreenSize]} {
          ${getTextStyles(theme, responsiveSize)}
        }
      `
    })

  const textStyles = textStylesOptions[size]
  return css`
    font-size: ${textStyles.fontSize};
    line-height: ${textStyles.lineHeight};
  `
}

const getPaddingStyles = (
  theme: ITheme,
  size: ResponsiveProp<TagSize>,
): FlattenSimpleInterpolation => {
  const makePadding = (padding: string) =>
    `0 ${padding} 0 calc(${padding} + 1px)`
  const paddingOptions: {
    [key in TagSize]: string
  } = {
    sm: makePadding(theme.spacing.xs),
    md: makePadding(theme.spacing.xs),
    lg: makePadding(theme.spacing.sm),
  }

  if (typeof size === 'object')
    return Object.entries(size).map(([breakpoint, responsiveSize]) => {
      return css`
        ${theme.media[breakpoint as ScreenSize]} {
          ${getPaddingStyles(theme, responsiveSize)}
        }
      `
    })

  const paddingStyles = paddingOptions[size]
  return css`
    padding: ${paddingStyles};
  `
}

const Container = styled.div<{
  rotate?: number
  size?: ResponsiveProp<TagSize>
}>(({ rotate = 0, size = 'md', theme }) => {
  const { colors, spacing } = theme

  const rotateStyle =
    rotate &&
    css`
      transform: rotate(${rotate || 0}deg);
      transform-origin: center;
    `

  return css`
    display: inline-flex;

    > ${Text} {
      background-color: ${colors.secondary.main};
      color: ${colors.neutral.darkest};
      padding: 0 ${spacing.sm} 0 calc(${spacing.sm} + 1px);
      margin-left: -1px;
      ${getTextStyles(theme, size)}
      ${getPaddingStyles(theme, size)}
    }

    ${rotateStyle}
  `
})

type TagSize = 'sm' | 'md' | 'lg'

export interface TagProps {
  label: string
  icon: (typeof iconNames)[number]
  size: ResponsiveProp<TagSize>
  rotation: number
}

const RefTag: React.FC<TagProps> = ({ icon, rotation, size, label }) => {
  const iconSizeOptions: { [key in TagSize]: BoxSize } = {
    sm: 'sm',
    md: 4,
    lg: 5,
  }

  const textVariantOptions: { [key in TagSize]: TextVariantOptions } = {
    sm: 'body100',
    md: 'subtitle100',
    lg: 'title200',
  }

  let iconSize: BoxSize | undefined
  let textVariant: TextVariantProp | undefined
  if (typeof size === 'string') {
    iconSize = iconSizeOptions[size]
    textVariant = textVariantOptions[size]
  } else if (typeof size === 'object') {
    iconSize = Object.entries(size).reduce<BoxSize>(
      (acc, [breakpoint, responsiveTagSize]) => ({
        // @ts-expect-error
        ...acc,
        [breakpoint as ScreenSize]: iconSizeOptions[responsiveTagSize],
      }),
      {} as BoxSize,
    )

    textVariant = Object.entries(size).reduce<TextVariantProp>(
      (acc, [breakpoint, responsiveTagSize]) => ({
        // @ts-expect-error
        ...acc,
        [breakpoint as ScreenSize]: textVariantOptions[responsiveTagSize],
      }),
      {} as TextVariantProp,
    )
  }

  return (
    <Container rotate={rotation} size={size}>
      <Icon name={icon} border colorScheme="neutral" size={iconSize ?? 'sm'} />
      <Text forwardedAs="span" variant={textVariant} bold>
        {label}
      </Text>
    </Container>
  )
}

export default RefTag

import { Button, Grid, Text } from '@lemonenergy/lemonpie'
import type { BaseSyntheticEvent } from 'react'
import React from 'react'
import styled, { css } from 'styled-components'

import customBorder from 'public/custom_border.png'
import Section from '~/components/Section'
import { gtag } from '~/utils/gtagEvents'
import { shortRandomName } from '~/utils/hashUtils'

const StyledContainer = styled(Grid.Container)(
  ({ theme: { media } }) => css`
    ${media.lg} {
      max-width: 85.375rem;
    }
  `,
)

const StyledTitle = styled(Text)(
  ({ theme: { fontSize, lineHeight, spacing, media } }) => css`
    margin-bottom: ${spacing.md};

    ${media.md} {
      font-size: ${fontSize(6)};
      line-height: ${lineHeight(7.25)};
    }
  `,
)

const StyledText = styled(Text)(
  ({ theme: { colors } }) => css`
    color: ${colors.primary.main};
  `,
)

export const GridContainer = styled.div(
  ({ theme: { media, spacing } }) => css`
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-column-gap: ${spacing.sm};

    ${media.md} {
      grid-auto-flow: column;
      grid-template-rows: repeat(2, auto);
      grid-column-gap: 0;
      grid-row-gap: ${spacing(8.25)};

      > *:last-child {
        transform: translateX(${spacing.xs});
      }
    }
  `,
)

export const GridImage = styled.div(
  ({ theme: { media } }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    ${media.md} {
      flex-direction: row;
    }
  `,
)

export const GridContent = styled.div(
  ({ theme: { media, spacing } }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${spacing.xs};

    ${media.md} {
      justify-content: flex-start;
      margin: 0 ${spacing.xs};
    }
  `,
)

export const Connector = styled.div(
  ({ theme: { borderWidth, colors, media } }) => css`
    height: 1.5rem;
    width: 0;
    border: ${borderWidth.main} dashed ${colors.primary.main};
    border-image-source: url(${customBorder});
    border-image-width: 1;
    border-image-slice: 1;
    border-image-repeat: round;

    ${media.md} {
      width: 100%;
      height: 0;
    }
  `,
)

export const Spacer = styled.div(
  ({ theme: { media } }) => css`
    ${media.md} {
      flex: 1 1 100%;
    }
  `,
)

const ActionWrapper = styled.div(
  ({ theme: { spacing, media } }) => css`
    margin-top: ${spacing(6)};

    ${media.md} {
      margin-top: ${spacing(12)};
    }
  `,
)

const StyledSubtitle = styled(Text)(
  ({ theme: { spacing, media } }) => css`
    margin-bottom: ${spacing(6)};

    ${media.md} {
      margin-bottom: ${spacing(12)};
    }
  `,
)

type Step = {
  img: string
  title?: string
  description: string
}

type CPFLHowItWorksProps = {
  withActionButton: boolean
  title: string
  subtitle: string
  steps: Step[]
}

const CPFLHowItWorks = ({
  title,
  subtitle,
  steps = [],
  withActionButton,
}: CPFLHowItWorksProps) => {
  const trackButtonClick = (e: BaseSyntheticEvent) => {
    gtag(
      {
        event: 'click_interaction',
        interaction_type: 'button',
        source_id: 'cpfl_how_it_works_button',
        label: 'Clique no botão de simular economia na seção de como funciona',
      },
      e,
    )
  }

  return (
    <Section>
      <StyledContainer>
        <StyledTitle variant="title200" textAlign="center">
          {title}
        </StyledTitle>
        <StyledSubtitle
          bold
          textAlign="center"
          variant={{
            xs: 'subtitle200',
            md: 'title200',
          }}
        >
          {subtitle}
        </StyledSubtitle>
        <GridContainer>
          {steps.map((step, index) => {
            const isLast = index + 1 === steps.length
            const isFirst = index === 0

            return (
              <React.Fragment key={shortRandomName()}>
                <GridImage>
                  {isFirst ? <Spacer /> : <Connector />}
                  <img
                    src={step.img}
                    alt={`Imagem representando a ${index + 1}º etapa`}
                  />
                  {isLast ? <Spacer /> : <Connector />}
                </GridImage>
                <GridContent>
                  {step?.title ? (
                    <>
                      <Text bold>{step.title}</Text>
                      <Text>{step.description}</Text>
                    </>
                  ) : (
                    <StyledText variant="body200">
                      {step.description}
                    </StyledText>
                  )}
                </GridContent>
              </React.Fragment>
            )
          })}
        </GridContainer>
        {withActionButton && (
          <Grid.Row>
            <Grid.Col
              md={4}
              offset={{
                md: 4,
              }}
            >
              <ActionWrapper>
                <Button onClick={trackButtonClick}>
                  Garantir minha reserva
                </Button>
              </ActionWrapper>
            </Grid.Col>
          </Grid.Row>
        )}
      </StyledContainer>
    </Section>
  )
}

export default CPFLHowItWorks

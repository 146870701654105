import { Builder } from '@builder.io/react'

import GenSustainableEnergy from './GenSustainableEnergy'

const register = () =>
  Builder.registerComponent(GenSustainableEnergy, {
    name: 'Gen Sustainable Energy',
    inputs: [
      {
        name: 'link',
        type: 'string',
      },
    ],
  })

export default { register }

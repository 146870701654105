import { Button, Grid, Text } from '@lemonenergy/lemonpie'
import type { BaseSyntheticEvent } from 'react'
import styled, { css } from 'styled-components'

import { type TagBuilderType } from '~/builder/components/Tag/Tag'
import Section from '~/components/Section'
import Tag from '~/components/Tag'
import { gtag } from '~/utils/gtagEvents'

const StyledContainer = styled(Grid.Container)(
  ({ theme: { media } }) => css`
    ${media.lg} {
      max-width: 85.375rem;
    }

    ${Text} {
      white-space: pre-line;
    }
  `,
)

const ZeroCostCol = styled.div(
  ({ theme: { media, spacing } }) => css`
    margin-bottom: ${spacing(8)};

    ${media.md} {
      margin-bottom: 0;
    }
  `,
)

const ZeroCostText = styled(Text)(
  ({ theme: { fontSize, fontWeight, media, spacing } }) => css`
    font-size: ${spacing(12)};
    font-weight: ${fontWeight.semibold};
    line-height: ${spacing(11)};
    margin-bottom: ${spacing(3)};

    ${media.sm} {
      font-size: ${fontSize(17.5)};
      line-height: ${fontSize(16.375)};
    }
  `,
)

const StyledCol = styled(Grid.Col)(
  ({ theme: { spacing, colors, media } }) => css`
    > *:first-child {
      margin-bottom: ${spacing.lg};
    }

    ${Button} {
      margin-top: ${spacing(5)};
      color: ${colors.primary.main};
    }

    ${media.md} {
      > *:first-child {
        margin-bottom: ${spacing.md};
      }
    }
  `,
)

const StyledTag = styled(Tag)(
  ({ theme: { spacing } }) => css`
    margin-top: ${spacing.xs};
    transform-origin: top left;
  `,
)

type ZeroCostProps = {
  title: string
  subtitle: string
  description: string
  ctaButtonLabel: string
  tag: TagBuilderType
}
const ZeroCost = ({
  title,
  subtitle,
  description,
  ctaButtonLabel,
  tag,
}: ZeroCostProps) => {
  const trackButtonClick = (e: BaseSyntheticEvent) => {
    gtag(
      {
        event: 'click_interaction',
        interaction_type: 'button',
        source_id: 'cpfl_zero_cost_button',
        label: 'Clique no botão de simular economia na seção de custo zero',
      },
      e,
    )
  }

  return (
    <Section variant="primary.main">
      <StyledContainer>
        <Grid.Row
          align={{
            xs: 'start',
            md: 'center',
          }}
        >
          <Grid.Col xs={12} md={6}>
            <ZeroCostCol>
              <ZeroCostText>{title}</ZeroCostText>
              <StyledTag name={tag.icon} rotate={tag.rotation} size={tag.size}>
                {tag.label}
              </StyledTag>
            </ZeroCostCol>
          </Grid.Col>
          <StyledCol xs={12} md={6}>
            <Text bold variant={{ xs: 'subtitle100', sm: 'title100' }}>
              {subtitle}
            </Text>
            <Text variant={{ xs: 'body100', sm: 'title200' }}>
              {description}
            </Text>

            <Button color="neutral" type="submit" onClick={trackButtonClick}>
              {ctaButtonLabel}
            </Button>
          </StyledCol>
        </Grid.Row>
      </StyledContainer>
    </Section>
  )
}

export default ZeroCost

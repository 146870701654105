import { Button } from '@lemonenergy/lemonpie'
import { Form as RemixForm, useSubmit } from '@remix-run/react'
import type { PropsWithChildren } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

type FormProps = {}

const Form = ({ children }: PropsWithChildren<FormProps>) => {
  const submit = useSubmit()
  const form = useForm()

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const result = await form.trigger()

    if (!result) return

    submit(e.target as HTMLFormElement)
  }

  return (
    <FormProvider {...form}>
      <RemixForm method="post" onSubmit={onSubmit}>
        <div>{children}</div>
        <Button type="submit">Enviar</Button>
      </RemixForm>
    </FormProvider>
  )
}

export default Form

import { Grid } from '@lemonenergy/lemonpie'
import styled, { css } from 'styled-components'

import Mockup from 'public/mockup2_como-funciona.png'
import Image from '~/components/Image'
import Subtitle from '~/components/Subtitle'
import Tag from '~/components/Tag'
import Title from '~/components/Title'

const PaymentSection = styled.section(
  ({ theme: { spacing, media, colors } }) => css`
    padding: ${spacing(9)} 0 0;
    background-color: ${colors.primary.main};
    color: ${colors.neutral.lightest};

    ${TitleCol} {
      margin-bottom: ${spacing(8)};
    }

    ${media.md} {
      padding: ${spacing(30)} 0 0;

      ${TitleCol} {
        margin-bottom: ${spacing(30)};
      }
    }
  `,
)

const TitleCol = styled(Grid.Col).attrs({ md: 6 })(
  ({ theme: { spacing } }) => css`
    > ${Title} {
      margin-bottom: ${spacing.md};
    }
  `,
)

const MockupCol = styled(Grid.Col).attrs({
  md: 5,
  lg: 6,
  offset: { md: 1, lg: 0 },
  justify: { xs: 'flex-start', md: 'flex-end' },
})(
  ({ theme: { spacing } }) => css`
    position: relative;

    ${Tag} {
      position: relative;
      z-index: 1;
      justify-self: center;
      align-self: center;
      right: ${spacing(13.5)};
      top: ${spacing.md};
    }
  `,
)

const MockupImage = styled(Image)(
  () => css`
    &&& {
      background-size: contain;
      background-position: bottom center;
    }
  `,
)

const Payment = ({ withoutTag }: { withoutTag?: boolean }) => {
  return (
    <PaymentSection>
      <Grid.Container>
        <Grid.Row>
          <TitleCol>
            <Title>você não paga nada além da energia que gerar.</Title>
            <Subtitle>
              Para gerar energia, você arcará com os custos de associação. Pra
              distribuidora, você continua pagando o uso da rede de energia, que
              a gente chama de "frete". São os impostos e taxas de distribuição
              para a energia chegar até a sua casa. Simples e transparente.
            </Subtitle>
          </TitleCol>
          <MockupCol>
            {!withoutTag && (
              <Tag name="sun" rotate={-6.9} size={{ xs: 'sm', md: 'md' }}>
                sem pegadinhas
              </Tag>
            )}

            <MockupImage
              src={Mockup}
              height={{ xs: 43.9, md: '100%' }}
              title="Celular com conta de energia Lemon."
              alt="Celular mostrando como a conta de energia Lemon é simples, digital e fácil de entender."
            />
          </MockupCol>
        </Grid.Row>
      </Grid.Container>
    </PaymentSection>
  )
}

export default Payment

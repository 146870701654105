import { Builder } from '@builder.io/react'

import GenAmbevVideo from './GenAmbevVideo'

const register = () =>
  Builder.registerComponent(GenAmbevVideo, {
    name: 'Gen Ambev Video',
  })

export default { register }

import styled from 'styled-components'

export const LandingPageFallbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  flex: 1;
  align-self: center;
`

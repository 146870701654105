import { Builder } from '@builder.io/react'

import ArticlesSection from './ArticlesSection'

import { PublishersNames } from '~/constants/articles'

const register = (title = 'o que falam sobre a Lemon') =>
  Builder.registerComponent(ArticlesSection, {
    name: 'Articles Section',
    inputs: [
      {
        name: 'title',
        type: 'longText',
        defaultValue: title,
      },
      {
        name: 'articles',
        type: 'list',
        defaultValue: [
          {
            article: {
              title:
                'Mussum Ipsum, cacilds vidis litro abertis. Nec orci ornare consequat',
              link: 'google.com',
              publisherName: 'oglobo',
              publisherLogo:
                'https://cdn.builder.io/api/v1/image/assets%2Fdc56bb5c9915477aaa0a5b682d15be60%2F949064cc4dd64cebbfb0bfe199b40cf4',
            },
          },
        ],
        subFields: [
          {
            name: 'article',
            type: 'object',
            subFields: [
              {
                name: 'title',
                friendlyName: 'Titulo do artigo',
                type: 'longText',
                required: true,
              },
              {
                name: 'link',
                friendlyName: 'Link do artigo',
                type: 'string',
                required: true,
              },

              {
                name: 'publisherName',
                friendlyName: 'Nome revista',
                required: true,
                type: 'string',
                enum: Object.values(PublishersNames),
              },
              {
                name: 'publisherLogo',
                type: 'file',
                friendlyName: 'Logo revista',
                required: true,
                allowedFileTypes: ['png', 'jpg', 'jpeg'],
              },
            ],
          },
        ],
      },
    ],
  })

export default {
  register,
}

import { Builder } from '@builder.io/react'

import GenSavings from './GenSavings'

const register = () =>
  Builder.registerComponent(GenSavings, {
    name: 'Gen Savings',
    inputs: [
      {
        name: 'link',
        type: 'string',
      },
    ],
  })

export default { register }

import { Builder } from '@builder.io/react'

import GenGoodForThePlanet from './GenGoodForThePlanet'

const register = () =>
  Builder.registerComponent(GenGoodForThePlanet, {
    name: 'Gen Good For The Planet',
  })

export default { register }

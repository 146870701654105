import { Builder } from '@builder.io/react'
import { iconNames } from '@lemonenergy/lemonpie'

import Tag from './Tag'

const tagAvaiablesSizes = ['sm', 'md', 'lg']

const getInputs = (defaultLabel = 'vagas limitadas', rotation = 0) => [
  {
    name: 'icon',
    type: 'text',
    defaultValue: 'sun',
    enum: iconNames.flat(),
    required: true,
  },
  {
    name: 'rotation',
    type: 'number',
    required: true,
    defaultValue: rotation,
  },
  {
    name: 'size',
    type: 'object',
    defaultValue: {
      xs: 'md',
      sm: 'lg',
      md: 'lg',
      lg: 'lg',
      xl: 'lg',
    },
    subFields: [
      {
        name: 'xs',
        type: 'string',
        defaultValue: 'md',
        enum: tagAvaiablesSizes,
      },
      {
        name: 'sm',
        type: 'string',
        defaultValue: 'lg',
        enum: tagAvaiablesSizes,
      },
      {
        name: 'md',
        type: 'string',
        defaultValue: 'lg',
        enum: tagAvaiablesSizes,
      },
      {
        name: 'lg',
        type: 'string',
        defaultValue: 'lg',
        enum: tagAvaiablesSizes,
      },
      {
        name: 'xl',
        type: 'string',
        defaultValue: 'lg',
        enum: tagAvaiablesSizes,
      },
    ],
  },
  {
    name: 'label',
    type: 'string',
    defaultValue: defaultLabel,
  },
]

const getComponentDefinition = (defaultLabel?: string, rotation?: number) => ({
  name: 'tag',
  type: 'object',
  defaultValue: {
    icon: 'sun',
    size: {
      xs: 'md',
      sm: 'lg',
      md: 'lg',
      lg: 'lg',
      xl: 'lg',
    },
    rotation: rotation,
    label: defaultLabel,
  },
  subFields: getInputs(defaultLabel, rotation),
})

const register = (defaultLabel?: string, rotation?: number) =>
  Builder.registerComponent(Tag, {
    name: 'tag',
    inputs: getInputs(defaultLabel, rotation),
  })

export default {
  register,
  getComponentDefinition,
}

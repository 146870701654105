import { Button, Grid, Text } from '@lemonenergy/lemonpie'
import type { BaseSyntheticEvent } from 'react'
import styled, { css } from 'styled-components'

import { type TagBuilderType } from '../Tag/Tag'

import Section from '~/components/Section'
import Tag from '~/components/Tag'
import { gtag } from '~/utils/gtagEvents'

const StyledContainer = styled(Grid.Container)(
  ({ theme: { spacing, media } }) => css`
    margin-top: ${spacing(9)};
    white-space: pre-line;

    ${media.md} {
      margin-top: ${spacing(18)};
    }

    ${media.lg} {
      max-width: 85.375rem;
    }
  `,
)

const StyledCol = styled(Grid.Col)(
  ({ theme: { spacing, colors, media } }) => css`
    padding-bottom: ${spacing.md};
    display: flex;
    flex-direction: column;
    gap: ${spacing.md};

    > *:nth-child(2) {
      align-self: center;
    }

    ${Button} {
      color: ${colors.primary.main};
    }

    ${media.md} {
      gap: ${spacing.xl};
    }
  `,
)

const Title = styled(Text)(
  ({ theme: { fontSize, lineHeight, fontWeight, media } }) => css`
    ${media.md} {
      font-size: ${fontSize(6)};
      line-height: ${lineHeight(7.25)};
      font-weight: ${fontWeight.medium};
    }
  `,
)

const StyledImage = styled.img(
  () => css`
    max-width: 400px;
    min-width: 0;
    width: 100%;
    height: auto;
  `,
)

const StyledButton = styled(Button)(
  ({ theme: { media } }) => css`
    justify-content: center;

    ${media.md} {
      justify-content: space-between;
    }

    > span:last-child {
      display: none;

      ${media.md} {
        display: inherit;
      }
    }
  `,
)

const BottomCol = styled(Grid.Col)(
  () => css`
    align-self: flex-end;
  `,
)

type EverythingDigitalProps = {
  title: string
  subtitle: string
  ctaButtonLabel: string
  img: string
  tag: TagBuilderType
}

const EverythingDigital = ({
  title,
  subtitle,
  ctaButtonLabel,
  img,
  tag,
}: EverythingDigitalProps) => {
  const onButtonClick = (e: BaseSyntheticEvent) => {
    gtag(
      {
        event: 'click_interaction',
        interaction_type: 'button',
        source_id: 'cpfl_everything_digital_button',
        label: 'Clique no botão de simular economia na seção de tudo digital',
      },
      e,
    )
  }

  return (
    <Section variant="primary.main" noPadding>
      <StyledContainer>
        <Grid.Row>
          <StyledCol md={6}>
            <Tag name={tag.icon} size={tag.size} rotate={tag.rotation}>
              {tag.label}
            </Tag>
            <Title bold variant="title100">
              {title}
            </Title>
            <Text variant={{ xs: 'subtitle200', md: 'subtitle100' }}>
              {subtitle}
            </Text>
            <StyledButton
              color="neutral"
              onClick={onButtonClick}
              icon="arrow-right"
            >
              {ctaButtonLabel}
            </StyledButton>
          </StyledCol>
          <BottomCol sm={6} offset={{ sm: 3, md: 1 }} md={5}>
            <StyledImage src={img} alt="Imagem ilustrativa da conta digital." />
          </BottomCol>
        </Grid.Row>
      </StyledContainer>
    </Section>
  )
}

export default EverythingDigital

import { Builder } from '@builder.io/react'

import GenHero from './GenHero'

const register = () =>
  Builder.registerComponent(GenHero, {
    name: 'Gen Hero',
    inputs: [
      {
        name: 'title',
        type: 'string',
        defaultValue:
          'chegou a hora de gerar a sua própria energia e economizar sem precisar investir em nada',
      },
      {
        name: 'description',
        type: 'string',
        defaultValue:
          'Com a ajuda da Lemon, você sempre - e é sempre mesmo - vai ter energia limpa e economia para o seu negócio. Tudo digital!',
      },
      { name: 'image', type: 'file', allowedFileTypes: ['jpeg', 'png', 'jpg'] },
      {
        name: 'imageMobile',
        type: 'file',
        allowedFileTypes: ['jpeg', 'png', 'jpg'],
      },
      { name: 'backgroundColor', type: 'color' },
      { name: 'color', type: 'color' },
      {
        name: 'backgroundPosition',
        type: 'object',
        subFields: [
          { name: 'xs', type: 'string' },
          { name: 'sm', type: 'string' },
          { name: 'md', type: 'string' },
          { name: 'lg', type: 'string' },
          { name: 'xl', type: 'string' },
        ],
      },
      {
        name: 'backgroundSize',
        type: 'object',
        subFields: [
          { name: 'xs', type: 'string' },
          { name: 'sm', type: 'string' },
          { name: 'md', type: 'string' },
          { name: 'lg', type: 'string' },
          { name: 'xl', type: 'string' },
        ],
      },
    ],
  })

export default { register }

import { Select } from '@lemonenergy/lemonpie'
import type { Props } from '@lemonenergy/lemonpie/dist/Select/Select'
import { forwardRef } from 'react'

import { captureInteraction } from '~/utils/tracking'

const BaseSelectInput = forwardRef<HTMLSelectElement, Props>(
  ({ onBlur, ...props }, ref) => {
    const trackBlurEvent = (e: React.FocusEvent<HTMLSelectElement>) => {
      if (onBlur) onBlur(e)

      captureInteraction()(e)
    }

    return <Select ref={ref} onBlur={trackBlurEvent} {...props} />
  },
)

BaseSelectInput.displayName = 'BaseSelectInput'

export default BaseSelectInput

import {
  ArticlesSection,
  EverythingDigitalSection,
  FAQSection,
  HowItWorksSection,
  Tag,
} from './components'
import CPFLLemonPartners from './cpfl/CPFLLemonPartners'
import CPFLZeroCost from './cpfl/CPFLZeroCost'
import GenAmbevVideo from './gen/GenAmbevVideo'
import GenGoodForThePlanet from './gen/GenGoodForThePlanet'
import GenHero from './gen/GenHero'
import GenPayment from './gen/GenPayment'
import GenSavings from './gen/GenSavings'
import GenSustainableEnergy from './gen/GenSustainableEnergy'
import GenWhereabouts from './gen/GenWhereabouts'
import Form from './primitives/Form'
import FormField from './primitives/FormField'

export default function registerComponents() {
  Tag.register()
  CPFLZeroCost.register()
  CPFLLemonPartners.register()
  HowItWorksSection.register()
  ArticlesSection.register()
  EverythingDigitalSection.register()
  FAQSection.register()
  GenHero.register()
  GenSustainableEnergy.register()
  GenPayment.register()
  GenSavings.register()
  GenGoodForThePlanet.register()
  GenAmbevVideo.register()
  GenWhereabouts.register()
  Form.register()
  FormField.register()
}

import { Builder } from '@builder.io/react'

import GenWhereabouts from './GenWhereabouts'

const register = () =>
  Builder.registerComponent(GenWhereabouts, {
    name: 'Gen Whereabouts',
    inputs: [
      {
        name: 'link',
        type: 'string',
      },
    ],
  })

export default { register }

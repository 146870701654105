import { Grid, Text } from '@lemonenergy/lemonpie'
import styled, { css } from 'styled-components'

import Section from '~/components/Section'

const StyledCol = styled(Grid.Col)(
  ({ theme: { spacing, media } }) => css`
    padding: ${spacing.md} 0;
    display: flex;
    flex-direction: column;
    gap: ${spacing(6)};

    > *:first-child {
      align-self: center;
    }

    ${media.md} {
      gap: ${spacing.md};
    }
  `,
)

const StyledTitle = styled(Text)(
  ({ theme: { fontSize, media } }) => css`
    ${media.md} {
      font-size: ${fontSize(6)};
    }
  `,
)

const StyledSubtitle = styled(Text)(
  ({ theme: { spacing, media } }) => css`
    margin-bottom: ${spacing.md};

    ${media.md} {
      margin-bottom: ${spacing(9)};
    }
  `,
)

const LogoGrid = styled.div(
  ({ theme: { spacing, media } }) => css`
    display: grid;
    row-gap: ${spacing.md};
    column-gap: ${spacing.sm};
    grid-template-columns: repeat(2, 1fr);
    ${media.md} {
      grid-template-columns: repeat(6, 1fr);
    }
  `,
)

const LogoItem = styled.div(
  ({ theme: { borderStyle, borderWidth, radii, colors, spacing } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${borderWidth.xs} ${borderStyle.solid} ${colors.neutral.darkest};
    border-radius: ${radii.xs};
    padding: ${spacing.xs};
  `,
)

type LemonPartnerType = {
  logo: string
  name: string
}

type LemonPartnersType = {
  title: 'string'
  subtitle: 'string'
  partners: LemonPartnerType[]
}

const LemonPartners = ({
  title,
  subtitle,
  partners = [],
}: LemonPartnersType) => {
  return (
    <Section>
      <Grid.Container>
        <Grid.Row>
          <StyledCol>
            <StyledTitle bold variant="title100">
              {title}
            </StyledTitle>
            <StyledSubtitle
              bold
              textAlign="center"
              variant={{
                xs: 'subtitle200',
                md: 'title200',
              }}
            >
              {subtitle}
            </StyledSubtitle>
            {partners.length > 0 && (
              <LogoGrid>
                {partners.map(card => (
                  <LogoItem key={card.name}>
                    <img src={card.logo} alt={`Logo ${card.name}`} />
                  </LogoItem>
                ))}
              </LogoGrid>
            )}
          </StyledCol>
        </Grid.Row>
      </Grid.Container>
    </Section>
  )
}

export default LemonPartners

import { Button, Grid, Text } from '@lemonenergy/lemonpie'
import type { BaseSyntheticEvent } from 'react'
import styled, { css } from 'styled-components'

import Image from '~/components/Image'
import Section from '~/components/Section'
import Social from '~/components/Social'
import Subtitle from '~/components/Subtitle'
import Title from '~/components/Title'
import {
  StateList,
  StateListItem,
} from '~/routes/__site/como-funciona/__components/StateList'
import { gtag } from '~/utils/gtagEvents'

const TitleCol = styled(Grid.Col)(
  ({ theme: { spacing } }) => css`
    margin-bottom: ${spacing(9)};

    ${Title} {
      margin-bottom: ${spacing.md};
    }
  `,
)

const MapCol = styled(Grid.Col)(
  ({ theme: { spacing, media } }) => css`
    ${Image} {
      margin-bottom: ${spacing(6)};
      background-size: contain;

      ${media.lg} {
        background-position: right;
      }
    }
  `,
)

const StatesCol = styled(Grid.Col)(
  ({ theme: { spacing, media } }) => css`
    > ${Text}:first-child {
      margin-bottom: ${spacing.md};
    }

    > ${StateList} {
      margin-bottom: ${spacing.lg};
    }

    > ${Social} {
      margin-top: ${spacing.md};
    }

    > ${Button} {
      margin-bottom: ${spacing.md};
    }

    ${media.sm} {
      > ${Button} {
        max-width: 278px;
      }
    }
  `,
)

type GenWhereaboutsProps = {
  link: string
}

const Whereabouts = ({ link }: GenWhereaboutsProps) => {
  const trackButtonClick = (e: BaseSyntheticEvent) =>
    gtag(
      {
        event: 'click_interaction',
        source_id: 'where-about-button',
        interaction_type: 'button',
        label:
          'Botão da seção de onde a Lemon atua que direciona o usuário para o formulário',
      },
      e,
    )

  return (
    <Section>
      <Grid.Container>
        <Grid.Row>
          <TitleCol>
            <Title textAlign={{ xs: 'left', sm: 'center' }}>
              onde a Lemon já chegou:
            </Title>
            <Subtitle textAlign={{ xs: 'left', sm: 'center' }}>
              Economia com energia limpa para pequenos e médios negócios por
              todo o Brasil.
            </Subtitle>
          </TitleCol>
        </Grid.Row>
        <Grid.Row>
          <MapCol {...{ xs: 12, sm: 5.5 }}>
            <img
              src="https://images.energialemon.com.br/site/whereabouts_map.png?width=456&height=476&format=auto"
              alt="Mapa de estados que a lemon atende"
              loading="lazy"
              decoding="async"
              width={456}
              height={476}
            />
          </MapCol>
          <StatesCol {...{ xs: 12, sm: 5.5, offset: 1 }}>
            <Text variant={{ xs: 'subtitle200', sm: 'title300' }}>
              Localizações disponíveis
            </Text>
            <StateList>
              <StateListItem>Distrito Federal</StateListItem>
              <StateListItem>Minas Gerais</StateListItem>
              <StateListItem>Pernambuco</StateListItem>
              <StateListItem>Rio de Janeiro</StateListItem>
              <StateListItem>São Paulo</StateListItem>
              <StateListItem>Mato Grosso do Sul</StateListItem>
              <StateListItem>Goiás</StateListItem>
            </StateList>
            <Button onClick={trackButtonClick} to={link ?? '#cadastro'}>
              Fazer cadastro
            </Button>
            <Text variant={{ xs: 'body100', sm: 'subtitle200' }}>
              Acompanhe nossas redes sociais para saber novas localizações e
              distribuidoras.
            </Text>
            <Social colorScheme="dark" source="whereabout" />
          </StatesCol>
        </Grid.Row>
      </Grid.Container>
    </Section>
  )
}

export default Whereabouts

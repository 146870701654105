import { Builder } from '@builder.io/react'

import GenPayment from './GenPayment'

const register = () =>
  Builder.registerComponent(GenPayment, {
    name: 'Gen Payment',
  })

export default { register }

import styled, { css } from 'styled-components'

import LinkWithIcon from '~/components/LinkWithIcon'
import SustainableEnergy from '~/sections/SustainableEnergy'

const Container = styled(SustainableEnergy)(
  ({ theme: { spacing } }) => css`
    padding-bottom: ${spacing(9)};
  `,
)

type GenSustainableEnergyProps = {
  link: string
}

const GenSustainableEnergy = ({ link }: GenSustainableEnergyProps) => {
  return (
    <Container ctaHref={link ?? '#cadastro'}>
      <LinkWithIcon
        href="/sobre"
        name="arrow-right"
        rotateIcon={-45}
        bold
        id="sustainable-energy-know-more-link"
      >
        Saiba mais sobre a Lemon
      </LinkWithIcon>
    </Container>
  )
}

export default GenSustainableEnergy

import { Select } from '@lemonenergy/lemonpie'
import type { BaseSyntheticEvent } from 'react'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import StatesSelectOptions from '~/components/StatesSelectOptions'
import { allDiscosByStates } from '~/constants/discosByStates'
import errorMessages from '~/constants/errorMessages'
import type { BrazilianStatesKeys } from '~/constants/geo'
import { gtag } from '~/utils/gtagEvents'

const StateDisco = ({ stateLabel }: { stateLabel?: string }) => {
  const {
    formState: { errors },
    register,
    watch,
    setValue,
  } = useFormContext<{ state: BrazilianStatesKeys; disco: string }>()

  const selectedState = watch('state')
  const trackFocusEvent = (e: BaseSyntheticEvent) =>
    gtag({ event: 'input_start' }, e)
  const trackBlurEvent = (e: BaseSyntheticEvent) =>
    gtag(
      {
        event: 'input_submit',
        interaction_result: e.target.value,
      },
      e,
    )

  useEffect(() => {
    if (!selectedState) {
      setValue('disco', '')
    } else {
      setValue('disco', allDiscosByStates[selectedState][0].value)
    }
  }, [selectedState, setValue])

  return (
    <>
      <Select
        id="state"
        label={stateLabel ?? 'Estado'}
        error={errors.state?.message && errors.state.message}
        {...register('state', { required: errorMessages.REQUIRED })}
        data-testid="state-select"
        defaultValue=""
        onFocus={trackFocusEvent}
        onBlur={trackBlurEvent}
      >
        <StatesSelectOptions />
      </Select>

      <Select
        id="disco"
        label="Qual é a distribuidora de energia do estabelecimento?"
        {...register('disco', { required: errorMessages.REQUIRED })}
        defaultValue=""
        disabled={!selectedState}
        onFocus={trackFocusEvent}
        onBlur={trackBlurEvent}
      >
        <option label="" value="" disabled />

        {selectedState &&
          allDiscosByStates[selectedState].map(({ label, value }) => (
            <option value={value} key={label}>
              {label}
            </option>
          ))}

        <option value={'outras'}>Outras</option>
      </Select>
    </>
  )
}

export default StateDisco

import { Checkbox, Link, Text } from '@lemonenergy/lemonpie'
import { useNavigation } from '@remix-run/react'
import type { BaseSyntheticEvent } from 'react'
import { useFormContext } from 'react-hook-form'

import errorMessages from '~/constants/errorMessages'
import { gtag } from '~/utils/gtagEvents'

const PolicyConsentInput = ({ testid }: { testid?: string }) => {
  const form = useFormContext<{ termsOfUse?: boolean }>()
  const navigation = useNavigation()

  const {
    register,
    formState: { errors },
  } = form

  const { onChange, ...methods } = register('termsOfUse', {
    required: errorMessages.PRIVACY_POLICY,
  })

  const trackChangeEvent = (e: BaseSyntheticEvent) => {
    onChange(e)

    gtag(
      {
        event: 'checkbox',
        interaction_result: e.target.checked,
      },
      e,
    )
  }

  const loading = navigation.state !== 'idle'

  return (
    <Checkbox
      data-testid={testid}
      disabled={loading}
      error={errors.termsOfUse?.message}
      {...methods}
      onChange={trackChangeEvent}
    >
      <Text variant="body200">
        Li e concordo com a{' '}
        <Link
          href="/politica-de-privacidade"
          target="__blank"
          rel="noopener noreferrer"
        >
          Politica de Privacidade
        </Link>{' '}
        e{' '}
        <Link href="/termos-de-uso" target="__blank" rel="noopener noreferrer">
          Termos de Uso
        </Link>
      </Text>
    </Checkbox>
  )
}

export default PolicyConsentInput

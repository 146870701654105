import { Text, Grid } from '@lemonenergy/lemonpie'
import styled, { css } from 'styled-components'

import Forest from 'public/forest.jpg'
import Image from '~/components/Image'
import type { SectionProps } from '~/components/Section'
import Section from '~/components/Section'
import Subtitle from '~/components/Subtitle'
import Title from '~/components/Title'

const SectionWrapper = styled(Section)<SectionProps>(
  ({ theme: { media, spacing } }) => css`
    ${Grid.Col} {
      ${Title} {
        margin-bottom: ${spacing.md};
        text-align: center;
      }

      > ${Text}:not(${Title}) {
        margin-bottom: ${spacing(12)};
        text-align: center;
      }

      ${media.only.xs} {
        > ${Text}:not(${Title}) {
          margin-bottom: ${spacing(4.5)};
        }
      }
    }

    ${Grid.Container} {
      width: 100%;
    }

    ${media.only.xs} {
      ${Grid.Container}:last-child {
        padding: 0;
      }
    }
  `,
)

const GoodForThePlanet = () => (
  <SectionWrapper variant="neutral.light">
    <Grid.Container>
      <Grid.Row align="center">
        <Grid.Col align="center">
          <Title bold={{ xs: true, sm: false }} variant="title100">
            faz bem para o seu negócio, faz bem para o planeta
          </Title>
          <Subtitle>
            Energia sustentável não prejudica o meio ambiente como grandes
            hidrelétricas, nem polui como as termelétricas. Construir um
            presente mais sustentável é possível.
          </Subtitle>
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
    <Grid.Container>
      <Image
        height={{ xs: 30.5, sm: 80 }}
        src={Forest}
        alt="Grande floresta com árvores e rio vistos de cima, mostrando como a energia sustentável contribui para o meio ambiente."
        title="Floresta e rio vistos de cima."
      />
    </Grid.Container>
  </SectionWrapper>
)

export default GoodForThePlanet

import { Builder } from '@builder.io/react'

import ZeroCost from './CPFLZeroCost'

import Tag from '~/builder/components/Tag'

const register = () =>
  Builder.registerComponent(ZeroCost, {
    name: 'CPFL Zero Cost',
    inputs: [
      {
        name: 'title',
        type: 'longText',
        defaultValue: 'custo\nzero',
      },
      {
        name: 'subtitle',
        type: 'longText',
        defaultValue: 'R$ 0 de investimento\nR$ 0 de taxas\nR$ 0 de manutenção',
      },
      {
        name: 'description',
        type: 'longText',
        defaultValue:
          'As vagas nas usinas são limitadas. Garanta seu desconto mensal hoje mesmo e conta com a Lemon para economizar.',
      },
      {
        name: 'ctaButtonLabel',
        type: 'string',
        defaultValue: 'Simular Economia',
      },
      Tag.getComponentDefinition('vagas limitadas', -6),
    ],
  })

export default {
  register,
}

import { Builder, withChildren } from '@builder.io/react'

import FormComponent from './Form'

const Form = withChildren(FormComponent)

const register = () =>
  Builder.registerComponent(Form, {
    name: 'Form',
    defaultChildren: [
      {
        '@type': '@builder.io/sdk:Element',
        component: {
          name: 'Text',
          options: { text: 'Adicione seu conteúdo aqui' },
        },
      },
    ],
  })

export default { register }
